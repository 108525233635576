<template>
  <v-container fluid :class="desktop ? 'px-10' : null">
   <!-- Header -->
    <app-header
      title="Asistentes Bloqueados"
      :level="0"
      :btn="true"
      :loading="loading"
      @input="dialog = $event"
    />


    <!-- [header] -->
    <v-row align="center" v-if="desktop">
      <v-col>
        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-subtitle> Home / Asistentes </v-list-item-subtitle>
            <v-list-item-title class="text-h5 | font-weight-medium">
              Lista Negra
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col class="text-end">
        <v-btn @click="dialog = true" color="primary">Bloquear Asistente</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card :tile="desktop ? null : true" :flat="desktop ? null : true">
          <v-list class="py-0">
            <template v-for="(item, index) in items">
              <v-list-item :disabled="loading" two-line :key="item.rut">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ item.rut }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    v-model="item.disabled"
                    @change="changeState(item.rut, item.disabled)"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < items.length - 1" :key="index" />
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <dialog-searchUser
      v-model="dialog"
      @input="getItems"
      @snackbar="snackbar = true"
    />

    <v-snackbar v-model="snackbar" color="success" absolute right top>
      Usuario <strong>actualizado</strong> correctante
    </v-snackbar>

    <!-- [loading] -->
    <template v-if="loading">
      <v-skeleton-loader
        v-for="item in 4"
        :key="item"
        class="mb-2 mt-2"
        type="list-item-avatar-two-line"
      />
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DialogSearchUser from "../../components/attendees/AddBlackList.vue";

// 2023
import AppHeader from "@/layouts/components/Header.vue";

export default {
  components: { AppHeader, DialogSearchUser },

  data: () => ({
    items: [],
    dialog: false,
    loading: false,
    snackbar: false,
  }),

  computed: {
    ...mapState(["desktop"]),
  },

  methods: {
    ...mapActions("attendees", [
      "getDisabledAttendees",
      "updateDisabledAttendee",
    ]),

    async getItems() {
      this.loading = true;
      await this.getDisabledAttendees().then((resp) => {
        this.items = resp;
      });
      this.loading = false;
    },

    async changeState(id, state) {
      this.loading = true;
      await this.updateDisabledAttendee([id, state]);
      await this.getItems();
      this.snackbar = true;
      this.loading = false;
    },

    drawer() {
      this.$root.$emit("actionDrawers");
    },
  },

  created() {
    this.getItems();
  },

  beforeCreate() {
    this.$root.$emit("app-bar", "Lista Negra", 0);
  },
};
</script>