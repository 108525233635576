<template>
  <v-dialog
    max-width="450"
    persistent
    :value="value"
    :fullscreen="!desktop"
    :transition="desktop ? null : 'slide-x-reverse-transition'"
  >
    <v-card :tile="desktop ? null : true">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>Bloquear Asistente</v-toolbar-title>
        <v-spacer />
        <v-btn @click="close" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- [find] -->
      <v-form class="px-6 py-3" ref="form" @submit.prevent="onSearch">
        <v-row>
          <v-col cols="8">
            <v-text-field
              label="Rut"
              outlined
              v-if="value"
              v-model="findId"
              dense
              clearable
              hide-details
              @change="formatRut(findId)"
              :rules="[rules.required]"
              prepend-inner-icon="mdi-account"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              type="submit"
              block
              large
              >Buscar</v-btn
            >
          </v-col>
        </v-row>
      </v-form>

      <v-divider class="mb-5" />

      <v-card-text>
        <!-- not-found -->
        <template v-if="alert">
          <v-alert class="mb-0" dense border="left" type="info" color="grey">
            Usuario no encontrado o ya esta bloqueado
          </v-alert>
        </template>

        <!-- found -->
        <template v-if="found">
          <v-card outlined flat>
            <v-progress-linear
              :active="isLoading"
              indeterminate
              color="accent"
            />
            <v-list-item two-line>
              <v-list-item-avatar :color="found.disabled ? 'error' : 'success'">
                <v-icon dark>
                  {{ found.disabled ? "mdi-account-off" : "mdi-account-check" }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">
                  {{ found.name }}
                </v-list-item-title>

                <v-list-item-subtitle>
                  <v-icon small>mdi-smart-card-outline</v-icon>
                  {{ found.rut }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-switch
                  @change="changeState(found)"
                  v-model="found.disabled"
                />
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import Rut from "rutjs";
import { mapActions, mapState } from "vuex";
import { rules } from "../../assets/rules";

export default {
  props: ["value"],

  data() {
    return {
      alert: false,
      found: null,
      findId: "",
      rules: rules,
      loading: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapState(["desktop"]),
  },

  methods: {
    ...mapActions("attendees", [
      "findDisabledAttendee",
      "updateDisabledAttendee",
    ]),

    async onSearch() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        await this.findDisabledAttendee(this.findId).then((resp) => {
          if (!_.isUndefined(resp)) {
            this.alert = false;
            this.found = resp;
          } else {
            this.found = null;
            this.alert = true;
          }
        });

        this.loading = false;
      }
    },

    async changeState(v) {
      this.isLoading = true;
      await this.updateDisabledAttendee([v.rut, v.disabled]);
      this.isLoading = false;
      this.$emit("snackbar", true);
      this.close();
    },

    close() {
      this.$emit("input", false);
      this.found = null;
      this.findId = null;
    },

    formatRut(v) {
      var rut = new Rut(String(v));
      this.findId = rut.getNiceRut(false);
    },

    // timeout(ms) {
    // await this.timeout(4000);
    //   return new Promise((resolve) => setTimeout(resolve, ms));
    // },
  },
};
</script>